<script setup lang="ts">
import * as Sentry from "@sentry/vue";

definePageMeta({
  middleware: ["load-cms-page"],
});

const route = useRoute();
const { $api } = useNuxtApp();
const { stringToHash, getValueByKeyFromKeyValueData } = useHelpers();

// Create a ref to hold the cmsPage data
const cmsPage = ref(null);

if ("preview" in route.query && typeof route.query.preview === "string") {
  cmsPage.value = await $api("cmspage-from-url", {
    method: "POST",
    body: { url: useRoute().path, preview: true },
  }).catch((error) => {
    if (error?.statusCode === 404) {
      throw error;
    } else {
      Sentry.captureEvent({
        message: `Failed to fetch CMS page content from API - ER-XBN3`,
        level: "error",
        extra: {
          url: useRoute().path,
          error,
          preview: true,
        },
      });
    }
  });
} else {
  // console.log("pages/[...slug].vue: getting from redis: ", useRoute().path);
  const response = await $fetch("/api/redis", {
    method: "GET",
    params: { key: "cmsPage:" + stringToHash(useRoute().path) },
  }).catch(() => null);
  if (response?.success) {
    try {
      cmsPage.value = JSON.parse(response.value);
    } catch (error) {
      // console.log("error_cp4dB", error); // todo: get some dictionary about these, and send the to sentry
    }
  } else {
    // no redis present, fetch from API
    // console.log("pages/[...slug].vue: not found in redis, getting from api: " + useRoute().path);
    cmsPage.value = await $api("cmspage-from-url", {
      method: "POST",
      body: { url: useRoute().path },
    }).catch((error) => {
      if (error?.statusCode === 404) {
        throw error;
      } else {
        Sentry.captureEvent({
          message: `Failed to fetch CMS page content from API - ER-A3PA`,
          level: "error",
          extra: {
            url: useRoute().path,
            error,
          },
        });
      }
    });
  }
}

const isCmsPage = computed(() => cmsPage.value?.type === "cmspage");
const isContentElement = computed(
  () => cmsPage.value?.type === "contentelement",
);

const multilayerMenuParentId = computed(() => {
  const value = parseInt(
    getValueByKeyFromKeyValueData(
      cmsPage.value.settings?.key_value,
      "multilayer_menu",
    ),
  );
  return isNaN(value) ? 0 : value;
});

const multilayerMenuMaxLevels = computed(() => {
  const value = parseInt(
    getValueByKeyFromKeyValueData(
      cmsPage.value.settings?.key_value,
      "multilayer_menu_max_levels",
    ),
  );
  return isNaN(value) ? 1 : value;
});
</script>

<template>
  <div
    v-if="cmsPage && Number.isInteger(multilayerMenuParentId)"
    class="cmsPage"
  >
    <BreadcrumbBase
      v-if="route.path !== '/' && !multilayerMenuParentId && cmsPage.breadcrumb"
      :items="cmsPage.breadcrumb"
    />
    <PageMenuBase
      v-if="
        Number.isInteger(multilayerMenuParentId) && multilayerMenuParentId > 0
      "
      :parent-id="multilayerMenuParentId"
      :max-levels="multilayerMenuMaxLevels"
    />
    <CmsPage v-if="isCmsPage" :page-data="cmsPage" />
    <CmsPageContentElement
      v-else-if="isContentElement"
      :page-data="cmsPage"
    ></CmsPageContentElement>
  </div>
</template>
