<script setup lang="ts">
import { ref, watch } from "vue";
import { MeiliSearch } from "meilisearch";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LPolyline } from "@vue-leaflet/vue-leaflet";
import { decode } from "@googlemaps/polyline-codec";

const props = defineProps<{ pageData: Record<string, any> }>();
const config = useRuntimeConfig();
const client = new MeiliSearch({
  host: config.public.meilisearchHost,
  apiKey: config.public.meilisearchKey,
});
const contentElementsIndex = client.index(
  config.public.meilisearchIndexContentElements,
);

const { t } = useI18n();

// -----------------------
// reactive properties
// -----------------------
const contentElementMeilisearch = ref();
const titleRef = ref("");

const tileLayerUrl = ref(
  "https://tiles-turatippek.mountex.cloud/styles/hungary-hiking-klokantech-terrain-gl-style/{z}/{x}/{y}.png",
);
const tileLayerAttribution = ref("");

const mapZoom = ref(12);
const mapCenter = ref();

const polyline = ref();

// Leaflet map ref
const mapRef = ref(null);

// -----------------------
// computed properties
// -----------------------
const structuredContentRef = computed(() => {
  const pageContent = props.pageData;

  if (
    pageContent?.content?.pages &&
    Array.isArray(pageContent.content?.pages[0].frames[0].component.components)
  ) {
    return pageContent.content?.pages[0].frames[0].component.components;
  }

  return null;
});

// -----------------------
// composables
// -----------------------
useHead({
  htmlAttrs: {
    lang: config.public.meta.lang,
  },
  title: props.pageData?.meta?.title || props.pageData?.name,
});

if (props.pageData?.meta?.description) {
  useHead({
    meta: [
      {
        name: "description",
        content: `${props.pageData?.meta?.description}`,
      },
    ],
  });
}

if (props.pageData?.meta?.canonical) {
  useHead({
    link: [
      {
        rel: "canonical",
        href: `${props.pageData?.meta?.canonical}`,
      },
    ],
  });
}

if (props.pageData?.meta?.robots) {
  useHead({
    meta: [
      {
        name: "robots",
        content: `${props.pageData?.meta?.robots}`,
      },
    ],
  });
}

// -----------------------
// helper methods
// -----------------------
const formattedDescription = (description: string) => {
  return description.replace(/\r\n/g, "<br />");
};

const formatDistance = (distanceInMeters: number) => {
  // Convert distance to kilometers and round to 1 decimal place
  const distanceInKilometers = (distanceInMeters / 1000).toFixed(1);
  // Return formatted string with " km" appended
  return distanceInKilometers.replace(".", ",") + " km";
};

// Function to compute the center of a polyline
const computePolylineCenter = (path) => {
  // Initialize variables for min and max latitude and longitude
  let minLat = Number.MAX_VALUE;
  let maxLat = -Number.MAX_VALUE;
  let minLng = Number.MAX_VALUE;
  let maxLng = -Number.MAX_VALUE;

  // Loop through each coordinate in the path to find min and max values
  path.forEach((coord) => {
    const [lat, lng] = coord;
    minLat = Math.min(minLat, lat);
    maxLat = Math.max(maxLat, lat);
    minLng = Math.min(minLng, lng);
    maxLng = Math.max(maxLng, lng);
  });

  // Calculate the average latitude and longitude values
  const avgLat = ((minLat + maxLat) / 2).toFixed(5);
  const avgLng = ((minLng + maxLng) / 2).toFixed(5);

  // Return the center coordinate
  return [avgLat, avgLng];
};

const decodePolyline = () => {
  if (
    !contentElementMeilisearch.value
      .contentelement_turatipp_map_poly_line_property
  )
    return;

  // Encoded polyline string
  const encodedPolyline =
    contentElementMeilisearch.value
      .contentelement_turatipp_map_poly_line_property;

  // Decode the polyline
  const decodedPath = decode(encodedPolyline);
  // console.log(decodedPath);

  mapCenter.value = computePolylineCenter(decodedPath);
  // console.log("Center:", mapCenter.value);

  polyline.value = [
    {
      id: 1,
      color: "#ED1C24",
      latLongs: decodedPath,
    },
  ];

  // console.log(polyline.value);
};

// Function called when Leaflet map is ready
const onMapReady = () => {
  // console.log("map is ready");

  if (mapRef.value && polyline.value.length > 0) {
    const latLngs = polyline.value.flatMap((line) => line.latLongs);
    mapRef.value.leafletObject?.fitBounds(latLngs);
  }
};

const getDifficulty = (val: any) => {
  const parts = val.split("|");
  if (parts.length === 2) return parts[1];
  return val;
};

// -----------------------
// vue events
// -----------------------
watch(
  () => contentElementMeilisearch.value,
  () => {
    decodePolyline();
  },
);

const { hits: contentElementQueryHits = [] } =
  await contentElementsIndex.search("", {
    filter: [`id = ${props.pageData?.id}`],
    limit: 1,
  });

if (contentElementQueryHits.length === 1) {
  contentElementMeilisearch.value = contentElementQueryHits[0];
  titleRef.value = contentElementQueryHits[0].name;
}
</script>

<template>
  <template v-if="contentElementMeilisearch">
    <!-- Tudástár -->
    <template
      v-if="
        ['Tudástár', 'Tipy, Rady', 'Sfaturi'].includes(
          contentElementMeilisearch.content_type_name,
        )
      "
    >
      <template v-if="structuredContentRef">
        <template v-for="(item, index) in structuredContentRef" :key="index">
          <template v-if="item.tagName">
            <BaseTag
              :tag-name="item.tagName"
              :class="item.classes?.join(' ')"
              :attributes="item.attributes"
            >
              <CmsPageContentBlock
                v-bind="{
                  block: item,
                }"
              />
            </BaseTag>
          </template>
          <template v-else>
            <CmsPageContentBlock
              v-bind="{
                block: item,
              }"
            />
          </template>
        </template>
      </template>
    </template>

    <!-- Túratippek -->
    <div
      v-if="contentElementMeilisearch.content_type_name === 'Túratippek'"
      class="container px-3 lg:px-0"
    >
      <div
        class="grid grid-cols-1 md:grid-cols-2 gap-4 bg-brand-surface rounded-16 p-4 text-15 mb-5"
      >
        <div class="order-last md:order-first">
          <div v-if="mapCenter" style="height: 500px; width: 100%">
            <l-map
              ref="mapRef"
              v-model="mapZoom"
              v-model:zoom="mapZoom"
              :use-global-leaflet="false"
              :center="mapCenter"
              @ready="onMapReady()"
            >
              <l-tile-layer
                :url="tileLayerUrl"
                :attribution="tileLayerAttribution"
              />
              <l-polyline
                v-for="line in polyline"
                :key="line.id"
                :lat-lngs="line.latLongs"
                :color="line.color"
              />
            </l-map>
          </div>
        </div>
        <div>
          <h1 class="mb-3 !mt-0">
            <span class="text-2xl md:text-3xl font-bold">{{ titleRef }}</span>
          </h1>
          <!-- eslint-disable vue/no-v-html -->
          <p
            v-html="
              formattedDescription(
                contentElementMeilisearch.contentelement_turatipp_description2_property,
              )
            "
          />
          <!-- eslint-enable -->

          <!-- Location -->
          <span
            v-if="
              contentElementMeilisearch.contentelement_turatipp_location_property
            "
            class="bg-brand-secondary inline-block rounded-5 px-[12px] py-[5px] text-12 mr-16 mb-16 mt-16"
            >{{
              contentElementMeilisearch.contentelement_turatipp_location_property
            }}</span
          >

          <!-- Is round trip -->
          <span
            v-if="
              parseInt(
                contentElementMeilisearch.contentelement_turatipp_is_roundtrip_property,
              ) === 1
            "
            class="bg-brand-secondary inline-block rounded-5 px-[12px] py-[5px] text-12 mr-16 mb-16 mt-16"
            >{{ t("is_roundtrip_yes") }}</span
          >
          <span
            v-if="
              parseInt(
                contentElementMeilisearch.contentelement_turatipp_is_roundtrip_property,
              ) !== 1
            "
            class="bg-brand-secondary inline-block rounded-5 px-[12px] py-[5px] text-12 mr-16 mb-16 mt-16"
            >{{ t("is_roundtrip_no") }}</span
          >

          <div class="text-xl">
            <!-- Distance -->
            <div
              v-if="
                contentElementMeilisearch.contentelement_turatipp_distance_property
              "
            >
              <b>{{ t("distance") }}:</b>
              {{
                formatDistance(
                  contentElementMeilisearch.contentelement_turatipp_distance_property,
                )
              }}
            </div>

            <!-- Elevation gain -->
            <div
              v-if="
                contentElementMeilisearch.contentelement_turatipp_elevation_gain_property
              "
            >
              <b>{{ t("elevation_gain") }}:</b>
              {{
                contentElementMeilisearch.contentelement_turatipp_elevation_gain_property
              }}
              m
            </div>

            <!-- Difficulty -->
            <div
              v-if="
                contentElementMeilisearch.contentelement_difficulty_property
              "
            >
              <b>{{ t("difficulty") }}:</b>
              {{
                getDifficulty(
                  contentElementMeilisearch.contentelement_difficulty_property,
                )
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <template v-if="structuredContentRef">
          <template v-for="(item, index) in structuredContentRef" :key="index">
            <template v-if="item.tagName">
              <BaseTag
                :tag-name="item.tagName"
                :class="item.classes?.join(' ')"
                :attributes="item.attributes"
              >
                <CmsPageContentBlock
                  v-bind="{
                    block: item,
                  }"
                />
              </BaseTag>
            </template>
            <template v-else>
              <CmsPageContentBlock
                v-bind="{
                  block: item,
                }"
              />
            </template>
          </template>
        </template>
        <template v-else>
          <h2 class="my-4">
            <span class="text-2xl font-bold">{{ t("turaleiras") }}</span>
          </h2>
          <p
            v-if="
              contentElementMeilisearch.contentelement_turatipp_description2_property
            "
            v-html="
              formattedDescription(
                contentElementMeilisearch.contentelement_turatipp_description2_property,
              )
            "
          />
        </template>
      </div>
    </div>

    <!-- Tudástár -->
    <template
      v-if="['Karrier'].includes(contentElementMeilisearch.content_type_name)"
    >
      <div class="container-s">
        <h1 class="font-lora !text-4xl font-bold">
          {{ contentElementMeilisearch.name }}
        </h1>

        <div class="h-px w-full bg-black my-5"></div>

        <h2 class="font-lora !text-2xl font-bold">{{ t("work_location") }}</h2>
        <div class="mb-5">
          {{ contentElementMeilisearch.contentelement_lokacio_property }}
        </div>

        <h2 class="font-lora !text-2xl font-bold">{{ t("work_position") }}</h2>
        <div class="mb-5">
          {{ contentElementMeilisearch.contentelement_pozicio_property }}
        </div>

        <h2 class="font-lora !text-2xl font-bold">{{ t("work_schedule") }}</h2>
        <div class="mb-5">
          {{ contentElementMeilisearch.contentelement_foglalkoztatas_property }}
        </div>

        <div class="h-px w-full bg-black my-5"></div>

        <template v-if="structuredContentRef">
          <div class="career-description">
            <template
              v-for="(item, index) in structuredContentRef"
              :key="index"
            >
              <template v-if="item.tagName">
                <BaseTag
                  :tag-name="item.tagName"
                  :class="item.classes?.join(' ')"
                  :attributes="item.attributes"
                >
                  <CmsPageContentBlock
                    v-bind="{
                      block: item,
                    }"
                  />
                </BaseTag>
              </template>
              <template v-else>
                <CmsPageContentBlock
                  v-bind="{
                    block: item,
                  }"
                />
              </template>
            </template>
          </div>
        </template>
      </div>
    </template>
  </template>
</template>
